import React from 'react';
// import GfnuLogo from '../img/gfnu.png';
import { MemberDiv } from './Membership.styled';

export const AboutUs = () => {
  return (
    <MemberDiv>
      <p>
        Гільдія фахівців з нерухомості України заснована у 2010 році. Вона є
        однією з наймасовіших організацій рієлтор України та наразі нараховує
        1785 членів. Ми об'єднання по справжньому професійних рієлторів які
        працюють на ринку нерухомості України не менше трьох років. ми вважаємо
        що професіонал в нашій галузі це той хто провів десятки, а може і за
        сотню угод і має відповідну репутацію. А не той хто позавчора закінчив
        тижневі курси за 4000 гривень і отримав сертифікат від незрозуміло кого.
        Всім нам треба вчитись і підвищувати свій рівень освіти, але треба
        поважати не тільки численних коучів, а і себе і свій досвід. ГФНУ це
        суто рієлторська організація, ми не утримуємо адміністративний апарат,
        всі проблеми вирішуємо разом. Тому у ГФНУ не існує і не буде існувати
        ніяких членських внесків і поборів. Свідоцтва та посвідчення члена ГФНУ
        мають два ступені захисту і користуються великим авторитетом у населення
        і в ріелторському середовищі.
      </p>
      <span>Приєднуйся до нашої спільноти, будь рівним серед кращих!</span>
      <p>Кому не місце у ГФНУ : </p>
      <ul>
        <li>
          особам які співпрацювали з громадськими організаціями та громадянами
          країни-агресора після лютого 2014 року.
        </li>
        <li>особам які мають проблеми з законами України.</li>
        <li>
          особам які сприяли прийняттю Законів про регулювання ріелторської
          діяльності потім визнаними Аудиторською Палатою України як ті що мають
          ознаки корупції.
        </li>
        <li>
          особам які намагаються нав'язувати іншим операторам ринку нерухомості
          свій стиль праці з замовниками послуг.
        </li>
        <li>
          ми за набуття знань і корисних навичок і співпрацюємо з тими хто їх
          втілює, але ГФНУ є організацією тільки реально практикуючих ріелторів.
          Тих ріелторів які реально працюють в регіонах України, яких знають і
          поважають колеги. При всій повазі ми не приймаємо до себе фахівців
          суміжних сфер діяльності - коучів, оцінювачів тощо.
        </li>
      </ul>
    </MemberDiv>
  );
};
